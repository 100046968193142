<template>
	<div class="technology--container" v-if="gallery">
		<div class="technology--arrow-prev" :class="this.currents == 1 ? 'inactive' : ''">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
			</svg>
		</div>
		<div class="technology--arrow-next" :class="this.currents == this.totalSlides ? 'inactive' : ''">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
			</svg>
		</div>
		<div class="technology--carousel">
			<div class="wrapper--carousel">
				<div class="carousel--item" v-for="(item, index) in gallery" :key="index" :order="index+1">
					<div class="item--image">
						<ImageById :media_id="item" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ImageById from '@/components/ImageById.vue'
import Draggabilly from 'draggabilly';
import gsap from 'gsap'
export default {
	data(){
		return {
			carousel: '',
			container: '',	
			currents: 1,
			prevDirection: 0,
			direction: 0,
			dir: '',
			totalSlides: 0
		}
	},
	components: {
		ImageById
	},
	props: {
		gallery: {
			type: Array,
			required: true,
		},
		triggered: {
			type: Boolean,
		},
	},
	methods: {
		initCarousel() {
			this.container = document.querySelector('.technology--carousel')

			this.carousel = new Draggabilly( this.container, {
				axis: 'x'
			});
		},
		calcWinsize(){
			this.wizSize = { width:window.innerWidth, height: window.innerHeight}
		},
		isIpadPro() {
			let response;
			let ratio = window.devicePixelRatio || 1;
			let screen = {
				width : window.screen.width * ratio,
				height : window.screen.height * ratio
			};
			if(
			(screen.width === 2048 && screen.height === 2732) || //iPad Pro Portrait
			(screen.width === 2732 && screen.height === 2048) || //iPad Pro Landscape
			(screen.width === 1536 && screen.height === 2048) || //iPad
			(screen.width === 2048 && screen.height === 1536)){	
				response = true;
			}else {
				response = false;
			}
			return response;
		},
		isMobile() {
			if(this.wizSize.width <= 768 || this.isIpadPro()){
				return true;
			}else{
				return false;
			}
		},
		setSliderWidth(){
			let width = 0;
			let elements = this.container.querySelector('.wrapper--carousel').querySelectorAll('.carousel--item');
			[...elements].forEach((el) => {
				let style = el.currentStyle || window.getComputedStyle(el);
				width += (el.offsetWidth + parseFloat(style.marginRight));
			});
			let list = this.container.querySelector('.wrapper--carousel');
			list.style.width = (width + 'px');
    },
		animateCarousel() {
			let allSlides = this.container.querySelectorAll('.carousel--item').length;
			this.totalSlides = allSlides;
			let list = this.container.querySelector('.wrapper--carousel');
			let style = list.currentStyle || window.getComputedStyle(list);
			let list_width = list.offsetWidth - parseFloat(style.paddingLeft);
			let elements = list.querySelectorAll('.carousel--item');
			let first_el_rect = elements[0].getBoundingClientRect();
			let second_el_rect = elements[1].getBoundingClientRect();
			let third_el_rect = elements[2].getBoundingClientRect();

			let last_element = elements[elements.length-1];
			let limit_end = (list_width - last_element.offsetWidth);
			//let total_left = (list_width - (last_element.offsetWidth / 2));

			let prevBtn = document.querySelector('.technology--arrow-prev');
			let nextBtn = document.querySelector('.technology--arrow-next');
			
			let htmlElement = elements[0];
			let stl = window.getComputedStyle(htmlElement);
			let mrg = parseFloat(stl.marginRight);

			elements[0].classList.add('current-active-slide');
			elements[1].classList.add('next-active-slide');

			let grid = {
				main: {
					start: first_el_rect.left,
					end: first_el_rect.right,
					width: first_el_rect.width,
					margin: mrg
				},
				next: {
					start: second_el_rect.left,
					end: second_el_rect.right,
					width: second_el_rect.width
				},
				next2: {
						start: third_el_rect.left,
						end: third_el_rect.right,
						width: third_el_rect.width
				}
			}

			this.carousel.on( 'pointerMove', ( event ) => {
				let cursor = document.getElementById('cursor')
				gsap.to(cursor, {x: event.clientX, y: event.clientY, duration: 0.5, ease: 'power2.out'})
			});

			this.carousel.on('dragStart', () => {
				if(parseInt(this.currents) > 1){
					let prevSlide = parseInt(this.currents) - 1;
					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + prevSlide + '"]').classList.remove('prev-active-slide');
				}

				if(parseInt(this.currents) < allSlides){
					let nextSlide = parseInt(this.currents) + 1;
					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextSlide + '"]').classList.remove('next-active-slide');
				}
			});

			this.carousel.on( 'dragMove', () => { //( event, pointer, moveVector )
				let x = this.carousel.position.x;
				this.direction = x;
				if(this.direction < this.prevDirection){
						this.dir = 'left';
						this.prevDirection = this.direction;
				}
				if(this.direction > this.prevDirection){
						this.dir = 'right';
						this.prevDirection = this.direction;
				}

				let mrk = grid.main.start;
				let trigger = grid.main.start - grid.main.width;

				let mrk_2 = grid.next.start;
				let trigger_2 = grid.next.start - grid.next.width;

				let mrk_3 = grid.next2.start;
				let trigger_3 = grid.next2.start - grid.next2.width;

				[...elements].forEach((el) => {
					let ord = parseInt(el.getAttribute('order'));
					let rect = el.getBoundingClientRect();

					if(rect.left <= mrk && rect.left >= trigger){
						let cont = el.querySelector('.item--image')
						let innerIMG = cont.querySelector('img')
						let perc = (rect.left - mrk) / (trigger - mrk); // 0 -> 1
						let scale = Number((1 - (Math.abs(perc)*0.2)).toFixed(5)); //1->0.8
						let p1 = Number((0 + (Math.abs(perc)*15)).toFixed(5)) + '%'; //0->15
						let p2 = Number((0 + (Math.abs(perc)*10)).toFixed(5)) + '%'; //0->10
						let p3 = Number((100 - (Math.abs(perc)*15)).toFixed(5)) + '%'; //100->85
						let p4 = Number((100 - (Math.abs(perc)*10)).toFixed(5)) + '%'; //100->90
						gsap.to(cont, {duration: 0.5, webkitClipPath:'polygon(' + p1 + ' ' + p2 + ', ' + p3 + ' ' + p2 + ', ' + p3 + ' ' + p4 + ', ' + p1 + ' ' + p4 + ')', clipPath:'polygon(' + p1 + ' ' + p2 + ', ' + p3 + ' ' + p2 + ', ' + p3 + ' ' + p4 + ', ' + p1 + ' ' + p4 + ')'})
						gsap.to(innerIMG, {duration: 0.5, scale: scale})
					}

					if(rect.left <= mrk_2 && rect.left >= trigger_2){
						let cont = el.querySelector('.item--image')
						let innerIMG = cont.querySelector('img')
						let perc_2 = (rect.left - mrk_2) / (trigger_2 - mrk_2); // 0->1
						let scale = Number((0.9 + (Math.abs(perc_2)*0.1)).toFixed(5)); //0.9->1
						let p1 = Number((15 - (Math.abs(perc_2)*15)).toFixed(5)) + '%'; //15->0
						let p2 = Number((10 - (Math.abs(perc_2)*10)).toFixed(5)) + '%'; //10->0
						let p3 = Number((85 + (Math.abs(perc_2)*15)).toFixed(5)) + '%'; //85->100
						let p4 = Number((90 + (Math.abs(perc_2)*10)).toFixed(5)) + '%'; //90->100
						gsap.to(cont, {duration: 0.5, webkitClipPath:'polygon(' + p2 + ' ' + p1 + ', ' + p4 + ' ' + p1 + ', ' + p4 + ' ' + p3 + ', ' + p2 + ' ' + p3 + ')', clipPath:'polygon(' + p2 + ' ' + p1 + ', ' + p4 + ' ' + p1 + ', ' + p4 + ' ' + p3 + ', ' + p2 + ' ' + p3 + ')'})
						gsap.to(innerIMG, {duration: 0.5, scale: scale})
					}

					if(rect.left <= mrk_3 && rect.left >= trigger_3){
						let cont = el.querySelector('.item--image')
						let innerIMG = cont.querySelector('img')

						let perc_3 = (rect.left - mrk_3) / (trigger_3 - mrk_3); // 0->1

						let scale = Number((0.8 + (Math.abs(perc_3)*0.1)).toFixed(5)); //0.8->0.9
						let p1 = Number((15 - (Math.abs(perc_3)*5)).toFixed(5)) + '%'; //15->10
						let p2 = Number((10 + (Math.abs(perc_3)*5)).toFixed(5)) + '%'; //10->15
						let p3 = Number((85 + (Math.abs(perc_3)*5)).toFixed(5)) + '%'; //85->90
						let p4 = Number((90 - (Math.abs(perc_3)*5)).toFixed(5)) + '%'; //90->85
						gsap.to(cont, {duration: 0.5, webkitClipPath:'polygon(' + p1 + ' ' + p2 + ', ' + p3 + ' ' + p2 + ', ' + p3 + ' ' + p4 + ', ' + p1 + ' ' + p4 + ')', clipPath:'polygon(' + p1 + ' ' + p2 + ', ' + p3 + ' ' + p2 + ', ' + p3 + ' ' + p4 + ', ' + p1 + ' ' + p4 + ')'})
						gsap.to(innerIMG, {duration: 0.5, scale: scale})
					}

					if(this.dir == 'left'){
						let limitForAutoPosition = grid.next.start;
						if(rect.left <= limitForAutoPosition){
							this.currents = ord;
						}
					}

					if(this.dir == 'right'){
						let limitForAutoPosition = grid.next.start - grid.next.width;
						if(rect.left <= limitForAutoPosition){
							this.currents = ord;
						}
					}
				})
			});

			nextBtn.addEventListener('click', () => {
				if(parseInt(this.currents) < allSlides){
					let nextSlide = parseInt(this.currents) + 1;
					let currentElement = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextSlide + '"]');
					let wrapper = currentElement.querySelector('.item--image');
					let wrapperImg = wrapper.querySelector('img');

					let delta =  grid.main.start - currentElement.offsetLeft;
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});
					this.currents++;
					let total = elements.length;

					if(parseInt(this.currents) > 2){
						let prevPrevSlide = parseInt(this.currents) - 2;
						this.container.querySelector('.wrapper--carousel .carousel--item[order="' + prevPrevSlide + '"]').classList.remove('prev-active-slide');
					}

					if(this.currents <= (total - 1)){
						let nx = this.currents + 1;
						let el_1 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nx + '"]');
						let wp_1 = el_1.querySelector('.item--image');
						if(el_1 !== null){
							gsap.to(wp_1, {duration: 0.5, webkitClipPath:'polygon(10% 15%, 90% 15%, 90% 85%, 10% 85%)', clipPath:'polygon(10% 15%, 90% 15%, 90% 85%, 10% 85%)', ease: 'power2.out'})
							gsap.to(wp_1.querySelector('img'), {duration: 0.5, scale: 0.9, ease: 'power2.out'})
						}
					}
					if(this.currents <= (total - 2)){
							let nxx = this.currents + 2;
							let el_2 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nxx + '"]');
							let wp_2 = el_2.querySelector('.item--image');
							if(el_2 !== null){
								gsap.to(wp_2, {duration: 0.5, webkitClipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', clipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', ease: 'power2.out'})
								gsap.to(wp_2.querySelector('img'), {duration: 0.5, scale: 0.8, ease: 'power2.out'})
							}
					}
					if(this.currents > 1){
						let pv = this.currents - 1;
						let el_3 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + pv + '"]')
						let wp_3 = el_3.querySelector('.item--image');
						if(el_3 !== null){
							gsap.to(wp_3, {duration: 0.5, webkitClipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', clipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', ease: 'power2.out'})
							gsap.to(wp_3.querySelector('img'), {duration: 0.5, scale: 0.8, ease: 'power2.out'})
						}
					}

					if(parseInt(this.currents) <= (allSlides - 1)){
							let nextSlide = parseInt(this.currents) + 1;
							this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
					}

					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + this.currents + '"]').classList.remove('next-active-slide');
					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');

					gsap.to(wrapper, {duration: 0.5, webkitClipPath:'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', clipPath:'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', ease: 'power2.out'})
					gsap.to(wrapperImg, {duration: 0.5,scale: 1, ease: 'power2.out'})
				}
			});

			prevBtn.addEventListener('click', () => {
				if(parseInt(this.currents) > 1){
					let prevSlide = parseInt(this.currents) - 1;
					let currentElement = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + prevSlide + '"]');
					let wrapper = currentElement.querySelector('.item--image');
					let wrapperImg = wrapper.querySelector('img');

					let delta =  grid.main.start - currentElement.offsetLeft;
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});

					this.currents--;
					let total = elements.length;

					if(parseInt(this.currents) > 1){
							let prevSlide = parseInt(this.currents) - 1;
							this.container.querySelector('.wrapper--carousel .carousel--item[order="' + prevSlide + '"]').classList.add('prev-active-slide');
					}

					if(this.currents <= (total - 1)){
						let nx = this.currents + 1;
						let el_1 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nx + '"]');
						let wp_1 = el_1.querySelector('.item--image');
						if(el_1 !== null){
							gsap.to(wp_1, {duration: 0.5, webkitClipPath:'polygon(10% 15%, 90% 15%, 90% 85%, 10% 85%)', clipPath:'polygon(10% 15%, 90% 15%, 90% 85%, 10% 85%)', ease: 'power2.out'})
							gsap.to(wp_1.querySelector('img'), {duration: 0.5, scale: 0.9, ease: 'power2.out'})
						}
					}
					if(this.currents <= (total - 2)){
							let nxx = this.currents + 2;
							let el_2 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nxx + '"]');
							let wp_2 = el_2.querySelector('.item--image');
							if(el_2 !== null){
								gsap.to(wp_2, {duration: 0.5, webkitClipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', clipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', ease: 'power2.out'})
								gsap.to(wp_2.querySelector('img'), {duration: 0.5, scale: 0.8, ease: 'power2.out'})
							}
					}
					if(this.currents > 1){
						let pv = this.currents - 1;
						let el_3 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + pv + '"]')
						let wp_3 = el_3.querySelector('.item--image');
						if(el_3 !== null){
							gsap.to(wp_3, {duration: 0.5, webkitClipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', clipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', ease: 'power2.out'})
							gsap.to(wp_3.querySelector('img'), {duration: 0.5, scale: 0.8, ease: 'power2.out'})
						}
					}

					/* if(parseInt(this.currents) < allSlides){
							let nextSlide = parseInt(this.currents) + 1;
							this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextSlide + '"]').classList.remove('current-active-slide');
							this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
							let el_1 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextSlide + '"]');
							let wp_1 = el_1.querySelector('.carousel--item-inner');
							if(el_1 !== null){
								if(this.isMobile()){
									gsap.to(wp_1, {duration: 0.5, x: '-20%', y: '-33%', scale: 0.6, ease: 'power2.out'})
								}else{
									gsap.to(wp_1, {duration: 0.5, x: '50%', y: '-33%', scale: 0.6, ease: 'power2.out'})
								}	
							}
					} */

					if(parseInt(this.currents) <= (allSlides - 2)){ 
							let nextNextSlide = parseInt(this.currents) + 2;
							this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextNextSlide + '"]').classList.remove('next-active-slide');
					}

					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + this.currents + '"]').classList.remove('prev-active-slide');
					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');
					
					gsap.to(wrapper, {duration: 0.5, webkitClipPath:'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', clipPath:'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', ease: 'power2.out'})
					gsap.to(wrapperImg, {duration: 0.5,scale: 1, ease: 'power2.out'})
				}
			});

			this.carousel.on( 'dragEnd', () => {
				let x = this.carousel.position.x;
				let currentElement = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + this.currents + '"]');
				let delta =  grid.main.start - currentElement.offsetLeft;

				this.container.querySelector('.wrapper--carousel .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');

				if(parseInt(this.currents) > 1){
					let prevSlide = parseInt(this.currents) - 1;
					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + prevSlide + '"]').classList.add('prev-active-slide');
				}

				if(parseInt(this.currents) < allSlides){
					let nextSlide = parseInt(this.currents) + 1;
					this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
				}

				let total = elements.length;

				if(x < 0 || x > -limit_end){
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});
					let wrapper = currentElement.querySelector('.item--image');
					let wrapperImg = wrapper.querySelector('img');

					if(this.currents <= (total - 1)){
						let nx = this.currents + 1;
						let el_1 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nx + '"]');
						let wp_1 = el_1.querySelector('.item--image');
						if(el_1 !== null){
							gsap.to(wp_1, {duration: 0.5, webkitClipPath:'polygon(10% 15%, 90% 15%, 90% 85%, 10% 85%)', clipPath:'polygon(10% 15%, 90% 15%, 90% 85%, 10% 85%)', ease: 'power2.out'})
							gsap.to(wp_1.querySelector('img'), {duration: 0.5, scale: 0.9, ease: 'power2.out'})
						}
					}
					if(this.currents <= (total - 2)){
							let nxx = this.currents + 2;
							let el_2 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + nxx + '"]');
							let wp_2 = el_2.querySelector('.item--image');
							if(el_2 !== null){
								gsap.to(wp_2, {duration: 0.5, webkitClipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', clipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', ease: 'power2.out'})
								gsap.to(wp_2.querySelector('img'), {duration: 0.5, scale: 0.8, ease: 'power2.out'})
							}
					}
					if(this.currents > 1){
						let pv = this.currents - 1;
						let el_3 = this.container.querySelector('.wrapper--carousel .carousel--item[order="' + pv + '"]')
						let wp_3 = el_3.querySelector('.item--image');
						if(el_3 !== null){
							gsap.to(wp_3, {duration: 0.5, webkitClipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', clipPath:'polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%)', ease: 'power2.out'})
							gsap.to(wp_3.querySelector('img'), {duration: 0.5, scale: 0.8, ease: 'power2.out'})
						}
					}
					gsap.to(wrapper, {duration: 0.5, webkitClipPath:'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', clipPath:'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', ease: 'power2.out'})
					gsap.to(wrapperImg, {duration: 0.5,scale: 1, ease: 'power2.out'})
				}

				if(x > 0){
					gsap.to(this.container, {duration: 0.5, left: 0, ease: 'power2.out'});
				}
				if(x < -limit_end){
					gsap.to(this.container, {duration: 0.5, left: -limit_end, ease: 'power2.out'});
				}
				//console.log(delta)
			})
		}
	},
	mounted() {
		this.initCarousel();
		this.setSliderWidth();
		this.animateCarousel();
	},
	beforeMount() {
		window.addEventListener('resize', () => {
			this.calcWinsize();
		});
	},
}
</script>

<style lang="scss">
.technology--container {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	.technology--arrow-prev {
		position: absolute;
		top: 50%;
		left: 5vw;
		width: 5vw;
		height: auto;
		z-index: 23;
		transform: translateY(-50%) rotate(180deg);
		transform-origin: center;
		transition: opacity .35s ease-in-out 0s;
		@include mobile {
			width: 15vw;
		}
		svg {
			fill: $black;
			width: 100%;
			height: auto;
			display: block;
		}
		&.inactive {
			opacity: 0;
			transition: opacity .35s ease-in-out 0s;
		}
	}
	.technology--arrow-next {
		position: absolute;
		top: 50%;
		right: 5vw;
		width: 5vw;
		height: auto;
		z-index: 23;
		transform: translateY(-50%);
		transition: opacity .35s ease-in-out 0s;
		@include mobile {
			width: 15vw;
		}
		svg {
			fill: $black;
			width: 100%;
			height: auto;
			display: block;
		}
		&.inactive {
			opacity: 0;
			transition: opacity .35s ease-in-out 0s;
		}
	}
	.technology--carousel {
		position: relative;
		width: auto;
		z-index: 22;
		.wrapper--carousel {
			position: relative;
			display: flex;
			padding-left: 20vw;
			@include mobile {
				padding-left: 5vw;
			}
			.carousel--item {
				position: relative;
				width: 50vw;
				flex-shrink: 0;
				height: 100%;
				transition-property: transform;
				@include mobile {
					width: 70vw
				}
				.item--image {
					position: relative;
					width: 100%;
					height: auto;
					transform-origin: center;
					clip-path: polygon(15% 10%, 85% 10%, 85% 90%, 15% 90%);
					img {
						position: relative;
						width: 100%;
						height: auto; 
						display: block;
						transform: scale(0.8);
					}
				}
				&[order="1"]{
					.item--image {
						clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
						img {
							transform: scale(1);
						}
					}
				}
				&[order="2"]{
					.item--image {
						clip-path: polygon(10% 15%, 90% 15%, 90% 85%, 10% 85%);
						img {
							transform: scale(0.9);
						}
					}
				}
			}
		}
	}
}
</style>